<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-img :max-height="imageHeight" :src="require('../assets/banner.png')"></v-img>
      </v-col>
    </v-row>

    <v-row class="br" style="height: 2vh"></v-row>

    <v-row>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="10">
            <v-row>
              <v-col>
                <div class="white-h4-font">ช่องทางการติดต่อทีมงาน</div>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col>
                <v-card color="#00B900" theme="dark" v-on:click="QR = !QR">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar class="ma-3" :size="avatarHeight" rounded="0">
                      <v-img :src="require('../assets/line.png')"></v-img>
                    </v-avatar>
                    <div class="d-flex align-center white-h4-font">
                      <v-avatar class="ma-3" :size="avatarHeight" rounded="0" v-if="QR">
                        <v-img :src="require('../assets/QR.png')"></v-img>
                      </v-avatar>
                      <v-card-text v-if="!QR">LINE: คลังอีเวนท์ ออแกไนเซอร์</v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="#0165E1" theme="dark" href="https://www.facebook.com/klangevent" target="_blank">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar class="ma-3" :size="avatarHeight" rounded="0">
                      <v-img :src="require('../assets/facebook.png')"></v-img>
                    </v-avatar>
                    <div class="d-flex align-center white-h4-font">
                      <v-card-text>
                        FACEBOOK: คลังอีเวนท์ ออแกไนเซอร์
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="#fbae00" theme="dark">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar class="ma-3" :size="avatarHeight" rounded="0">
                      <v-img :src="require('../assets/call.png')"></v-img>
                    </v-avatar>
                    <div class="d-flex align-center white-h4-font">
                      <v-card-text>
                        095-562-6414 <br>
                        095-653-6242
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="br" style="height: 2vh"></v-row>

        <v-row justify="center" align="center">
          <v-col cols="9">
            <v-row>
              <v-col>
                <div class="white-h4-font">ภาพบรรยากาศ</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row>
      <v-col>
        <v-container fluid>

          <!-- <v-row>
            <v-col justify="center" align="center">
              <v-slide-group show-arrows class="tran-bg">

                <template v-slot:next>
                  <v-icon color="white" large>mdi-menu-right</v-icon>
                </template>
                <template v-slot:prev>
                  <v-icon color="white" large>mdi-menu-left</v-icon>
                </template>
                <v-slide-item v-for="(itemMain, i) in imageLayout" :key="i">
                  <v-card width="400" class="ma-4 theme--light tran-card-bg"
                    @click="$router.push({ name: 'about', params: { label: itemMain.label, folderImg: itemMain.folderImg } })">
                    <v-card-title>
                      <v-img contain :src="itemMain.src"></v-img>
                    </v-card-title>
                    <v-card-text>
                      <b>{{ itemMain.label }}</b>
                    </v-card-text>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="col-4" justify="center" align="center" v-for="(itemMain, i) in imageLayout" :key="i">
              <v-card width="400" class="ma-4 theme--light tran-card-bg"
                @click="$router.push({ name: 'about', params: { label: itemMain.label, folderImg: itemMain.folderImg } })">
                <v-card-title>
                  <v-img contain :src="itemMain.src"></v-img>
                </v-card-title>
                <v-card-text>
                  <b>{{ itemMain.label }}</b>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <v-carousel cycle :height="imageHeightSlide">
                <v-carousel-item v-for="(item, i) in imageLayout" :key="i" :src="item.src"
                  @click="slideShowImage = true; folderImg = item.folderImg; $router.push({ name: 'about', params: { label: item.label, folderImg: item.folderImg } })"
                  :height="imageHeightSlide">
                  <v-container fill-height fluid pa-0 ma-0 pb-3>
                    <v-layout fill-height align-end pb-4 mb-4>
                      <v-flex xs12>
                        <v-card color="red" class="pa-2">
                          <span class="headline white--text" v-text="item.label"> </span>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row> -->
        </v-container>
      </v-col>
    </v-row>

    <br />

    <v-row class="br" style="height: 5vh"></v-row>

    <v-row>
      <v-col cols="12" justify="center" align="center">
        <v-img class="d-flex align-center" height="300" width="300" :src="require('../assets/logo.png')"></v-img>
      </v-col>
    </v-row>

    <v-row class="br" style="height: 5vh"></v-row>

    <div class="yellow-h4-font">บริษัท คลังอีเวนท์ ออแกไนเซอร์ จำกัด</div>
    <div class="white-h5-font">ประกอบธุรกิจให้บริการอย่างครบวงจร ก่อตั้งขึ้นเพื่อ</div>
    <div class="white-h5-font">รองรับงานอีเวนท์ทุกรูปแบบ ด้วยพลังความคิดสร้างสรรค์</div>
    <div class="white-h5-font">ภายใต้คอนเซ็ปต์ "ไม่มีอะไร....ที่เราสร้างสรรค์ไม่ได้" เพราะเรา</div>
    <div class="white-h5-font">คือบริษัทรับจัดงานยุค 4.0 เกาะติดเทรนด์และเทคโนโลยีใหม่ๆ</div>
    <div class="white-h5-font">โดยมีทีมงานมากคุณภาพที่มีประสบการณ์ในแต่ละด้านกว่า 10 ปี</div>

    <v-row class="br" style="height: 5vh"></v-row>

    <div class="yellow-h4-font">เราใส่ใจทุกรายละเอียดด้านการจัดงาน</div>
    <div class="white-h5-font">ด้วยการทำงานอย่างเป็นระบบและรวดเร็ว</div>
    <div class="white-h5-font">ตั้งแต่ประสานงาน เซ็ทอัพงานตลอดจนถึงจบงาน</div>

    <v-row class="br" style="height: 5vh"></v-row>

    <div class="yellow-h4-font">คลังอีเวนท์ ออแกไนเซอร์</div>
    <div class="white-h5-font">เชื่อมั่นในการให้บริการที่ดีเพื่อสร้างความผูกพันกับลูกค้า</div>
    <div class="white-h5-font">ในระยะยาว มากกว่าการทำเพียงเพื่อจบไปแต่ละงาน</div>

    <v-row class="br" style="height: 5vh"></v-row>

    <v-row class="br" style="height: 5vh"></v-row>

    <v-dialog v-model="slideShowImage" width="auto">
      <v-card>
        <v-carousel :height="500" :width="700">
          <v-carousel-item v-for="(item, i) in folderImg" :key="i" :src="item.src" :height="500" :width="700">
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <br />

    <v-row class="br" style="height: 5vh"></v-row>

    <v-row>
      <v-col>
        <div class="white-h4-font">บางส่วนของลูกค้าที่ใช้บริการของเรา</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-img :src="require('../assets/footer.png')" cover height="100%"></v-img>
      </v-col>
    </v-row>

    <v-row class="br" style="height: 5vh"></v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomePage",

  data: () => ({
    slideShowImage: false,
    folderImg: null,
    QR: false,
    items: [
      {
        src: require('../assets/banner.png'),
      },
    ],
    imageLayout: [
      {
        folderImg: [
          {
            src: require('../assets/event/event1/img1.jpg'),
          },
          {
            src: require('../assets/event/event1/img2.jpg'),
          },
          {
            src: require('../assets/event/event1/img3.jpg'),
          },
          {
            src: require('../assets/event/event1/img4.jpg'),
          },
          {
            src: require('../assets/event/event1/img5.jpg'),
          },
          {
            src: require('../assets/event/event1/img6.jpg'),
          },
          {
            src: require('../assets/event/event1/img7.jpg'),
          },
          {
            src: require('../assets/event/event1/img8.jpg'),
          },
          {
            src: require('../assets/event/event1/img9.jpg'),
          },
          {
            src: require('../assets/event/event1/img10.jpg'),
          },
        ],
        src: require('../assets/event/event1/img10.jpg'),
        label: "KRIANGKRAI STEELCENTER OUTING",
      },
      {
        folderImg: [
          {
            src: require('../assets/event/event2/img1.jpg'),
          },
          {
            src: require('../assets/event/event2/img2.jpg'),
          },
          {
            src: require('../assets/event/event2/img3.jpg'),
          },
          {
            src: require('../assets/event/event2/img4.jpg'),
          },
          {
            src: require('../assets/event/event2/img5.jpg'),
          },
        ],
        src: require('../assets/event/event2/img5.jpg'),
        label: "20 ANNIVERSARY KOTOBUKI TEC (THAILAND) CO.,LTD",
      },
      {
        folderImg: [
          {
            src: require('../assets/event/event3/img1.jpg'),
          },
          {
            src: require('../assets/event/event3/img2.jpg'),
          },
          {
            src: require('../assets/event/event3/img3.jpg'),
          },
          {
            src: require('../assets/event/event3/img4.jpg'),
          },
          {
            src: require('../assets/event/event3/img5.jpg'),
          },
          {
            src: require('../assets/event/event3/img6.jpg'),
          },
          {
            src: require('../assets/event/event3/img7.jpg'),
          },
          {
            src: require('../assets/event/event3/img8.jpg'),
          },
        ],
        src: require('../assets/event/event3/img1.jpg'),
        label: "MID YEAR PARTY 2022 (ไฮลี่ มาเรลลี)",
      },
      {
        folderImg: [
          {
            src: require('../assets/event/event4/img1.jpg'),
          },
          {
            src: require('../assets/event/event4/img2.jpg'),
          },
          {
            src: require('../assets/event/event4/img3.jpg'),
          },
          {
            src: require('../assets/event/event4/img4.jpg'),
          },
          {
            src: require('../assets/event/event4/img5.jpg'),
          },
          {
            src: require('../assets/event/event4/img6.jpg'),
          },
          {
            src: require('../assets/event/event4/img7.jpg'),
          },
          {
            src: require('../assets/event/event4/img8.jpg'),
          },
          {
            src: require('../assets/event/event4/img9.jpg'),
          },
          {
            src: require('../assets/event/event4/img10.jpg'),
          },
        ],
        src: require('../assets/event/event4/img2.jpg'),
        label: "NEW YEAR PARTY 2022 (ไอจิ ฟอร์ด)",
      },
      {
        folderImg: [
          {
            src: require('../assets/event/event5/img1.jpg'),
          },
          {
            src: require('../assets/event/event5/img2.jpg'),
          },
          {
            src: require('../assets/event/event5/img3.jpg'),
          },
          {
            src: require('../assets/event/event5/img4.jpg'),
          },
          {
            src: require('../assets/event/event5/img5.jpg'),
          },
          {
            src: require('../assets/event/event5/img6.jpg'),
          },
          {
            src: require('../assets/event/event5/img7.jpg'),
          },
          {
            src: require('../assets/event/event5/img8.jpg'),
          },
          {
            src: require('../assets/event/event5/img9.jpg'),
          },
          {
            src: require('../assets/event/event5/img10.jpg'),
          },
        ],
        src: require('../assets/event/event5/img6.jpg'),
        label: "FIFA WORLD CUP 2022",
      },
      {
        folderImg: [
          {
            src: require('../assets/event/event6/img1.jpg'),
          },
          {
            src: require('../assets/event/event6/img2.jpg'),
          },
          {
            src: require('../assets/event/event6/img3.jpg'),
          },
          {
            src: require('../assets/event/event6/img4.jpg'),
          },
          {
            src: require('../assets/event/event6/img5.jpg'),
          },
          {
            src: require('../assets/event/event6/img6.jpg'),
          },
          {
            src: require('../assets/event/event6/img7.jpg'),
          },
          {
            src: require('../assets/event/event6/img8.jpg'),
          },
          {
            src: require('../assets/event/event6/img9.jpg'),
          },
          {
            src: require('../assets/event/event6/img10.jpg'),
          },
          {
            src: require('../assets/event/event6/img11.jpg'),
          },
          {
            src: require('../assets/event/event6/img12.jpg'),
          },
          {
            src: require('../assets/event/event6/img13.jpg'),
          },
          {
            src: require('../assets/event/event6/img14.jpg'),
          },
          {
            src: require('../assets/event/event6/img15.jpg'),
          },
          {
            src: require('../assets/event/event6/img16.jpg'),
          },
          {
            src: require('../assets/event/event6/img17.jpg'),
          },
          {
            src: require('../assets/event/event6/img18.jpg'),
          },
        ],
        src: require('../assets/event/event6/img2.jpg'),
        label: "G.S. ELECTECH (THAILAND) CO.,LTD. SPORTS DAY",
      },
    ],
  }),
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '160'
        case 'sm': return '310'
        case 'md': return '500'
        case 'lg': return '600'
        case 'xl': return '700'
      }
    },
    imageHeightSlide() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '300'
        case 'sm': return '300'
        case 'md': return '500'
        case 'lg': return '600'
        case 'xl': return '700'
      }
    },
    marginSlide() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'margin: 10px;'
        case 'sm': return 'margin: 10px;'
        case 'md': return 'margin: 10px;'
        case 'lg': return 'margin: 10px;'
        case 'xl': return 'margin: 10px;'
      }
    },
    imageWidthSlide() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '350'
        case 'sm': return '350'
        case 'md': return '600'
        case 'lg': return '750'
        case 'xl': return '850'
      }
    },
    avatarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '50'
        case 'sm': return '60'
        case 'md': return '70'
        case 'lg': return '80'
        case 'xl': return '100'
      }
    },
  }
};
</script>
