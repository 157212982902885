<template>
  <div class="about">

    <br />
    <br />
    <br />

    <v-row>
      <v-col cols="12" justify="center" align="center">
        <v-img class="d-flex align-center" height="300" width="300" :src="require('../assets/logo.png')"></v-img>
      </v-col>
    </v-row>

    <br />

    <div class="white-h4-font">ภาพบรรยากาศงาน: {{ $route.params.label }}</div>

    <br />
    <br />

    <v-row>
      <v-col cols="6" v-for="(item, i) in   $route.params.folderImg  " :key="i" :justify="justifyLayouts(i)"
        :align="justifyLayouts(i)">
        <v-img class="d-flex align-center" height="500" width="700" :src="item.src"></v-img>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data: () => ({
    slides: [
      {
        title: 'TERRA PC-BUSINESS 5050S',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100955910000.jpg',
        price: 559
      },
      {
        title: 'TERRA PC-BUSINESS 5000',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100956890000.jpg',
        price: 609
      },
      {
        title: 'TERRA PC-Micro 6000SE SILENT GREENLINE ',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100955650000.jpg',
        price: 689
      },
      {
        title: 'HP ENVY 5030 multifunction printer',
        image: 'https://images-na.ssl-images-amazon.com/images/I/81DzbjMdK8L._AC_SX569_.jpg',
        price: 66
      },
      {
        title: 'TERRA PC-BUSINESS 5050S',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100955910000.jpg',
        price: 559
      },
      {
        title: 'TERRA PC-BUSINESS 5000',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100956890000.jpg',
        price: 609
      },
      {
        title: 'TERRA PC-Micro 6000SE SILENT GREENLINE ',
        image: 'https://www.wortmann.de/content/files/images/ProductImages/medium/item100955650000.jpg',
        price: 689
      },
      {
        title: 'HP ENVY 5030 multifunction printer',
        image: 'https://images-na.ssl-images-amazon.com/images/I/81DzbjMdK8L._AC_SX569_.jpg',
        price: 66
      }
    ]
  }),
  methods: {
    justifyLayouts(num) {
      let x = num % 2
      switch (x) {
        case 0: return 'right'
        case 1: return 'left'
      }
    }
  },
};
</script>