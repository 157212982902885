<template>
  <v-app>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<!-- <style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

.v-application {
  font-family: 'Itim', cursive;
}
</style> -->
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

.yellow-h4-font {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: 'Itim', cursive;
  text-align: center !important;
  color: #ffeb3b !important;
  caret-color: #ffeb3b !important;
}

.white-h4-font {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: 'Itim', cursive;
  text-align: center !important;
  color: #FFFFFF !important;
  caret-color: #FFFFFF !important;
}

.white-h5-font {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: 'Itim', cursive;
  text-align: center !important;
  color: #FFFFFF !important;
  caret-color: #FFFFFF !important;
}

#app {
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  background: url('../src/assets/BG.png') center center;
  background-size: cover;
  background-color: black;
}

.tran-bg {
  background-color: transparent;
  color: #FFFFFF !important;
}
</style>
